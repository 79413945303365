import { getDebugger } from '../libs/debug'
const debug = getDebugger('PandaTS', '#FFFFFF', '#130832000')

let loaded: boolean = false
const services = new Map<string, any>()
const IGNORED_SERVICES = ['chat-widget']

function populateSubServices(service: any) {
  const serviceKeys = Object.keys(service).filter((k) => k.endsWith('Service'))
  serviceKeys.forEach((k) => {
    if (!services.has(k)) {
      services.set(k, service[k])
      populateSubServices(service[k])
    }
  })
}

export function getPandaServices() {
  if (!loaded) {
    debug('Loading Panda Platform Services')
    const tradingPlatform = document.querySelector('panda-forex-trading-platform')
    if (!tradingPlatform) {
      debug('trading platform not found')
      return services
    }
    // @ts-expect-error - private property that we know exists
    if (!tradingPlatform._ngElementStrategy) {
      debug('angular element not found')
      return services
    }
    // @ts-expect-error - private property that we know exists
    const angularInjector = tradingPlatform._ngElementStrategy.injector
    if (!angularInjector) {
      debug('angular injector not found')
      return services
    }
    const providerList = new Map<string, any>()
    angularInjector.records.forEach((v: any, k: string) => {
      providerList.set(k, v)
    })
    let parent = angularInjector.parent
    while (parent) {
      if (parent && parent.records) {
        parent.records.forEach((v: any, k: string) => {
          providerList.set(k, v)
        })
      }
      if (parent && parent.parent) {
        parent = parent.parent
      } else {
        break
      }
    }
    providerList.forEach((v) => {
      if (v && v.value) {
        const keys = Object.keys(v.value).filter((k) => !IGNORED_SERVICES.includes(k))
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          if (key.endsWith('Service')) {
            services.set(key, v.value[key])
          }
        }
      }
    })
    services.forEach((v: any) => {
      populateSubServices(v)
    })
    debug('Panda Platform Services Loaded')
    loaded = true
  }
  return services
}
